export const GET_AUTH = "GET_AUTH";
export const GET_AUTH_FAILED = "GET_AUTH_FAILED";
export const GET_AUTH_REQUESTED = "GET_AUTH_REQUESTED";
export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS";

export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN";
export const GET_REFRESH_TOKEN_FAILED = "GET_REFRESH_TOKEN_FAILED";
export const GET_REFRESH_TOKEN_REQUESTED = "GET_REFRESH_TOKEN_REQUESTED";
export const GET_REFRESH_TOKEN_SUCCESS = "GET_REFRESH_TOKEN_SUCCESS";

export const GET_DEAL = "GET_DEAL";

export const GET_ADDRESS = "GET_ADDRESS";

export const ADD_ADDRESS_REQUESTED = "ADD_ADDRESS_REQUESTED";
export const ADD_ADDRESS_SUCCESS = "GADDADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";

export const GET_ADDRESS_REQUESTED = "GET_ADDRESS_REQUESTED";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILED = "GET_ADDRESS_FAILED";

export const DELETE_ADDRESS_REQUESTED = "DELETE_ADDRESS_REQUESTED";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";

export const EDIT_ADDRESS_REQUESTED = "EDIT_ADDRESS_REQUESTED";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILED = "EDIT_ADDRESS_FAILED";

export const GET_PROMOTION_REQUESTED = "GET_PROMOTION_REQUESTED";
export const GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS";
export const GET_PROMOTION_FAILED = "GET_PROMOTION_FAILED";

export const ADD_PROMOTION_REQUESTED = "ADD_PROMOTION_REQUESTED";
export const ADD_PROMOTION_SUCCESS = "ADD_PROMOTION_SUCCESS";
export const ADD_PROMOTION_FAILED = "ADD_PROMOTION_FAILED";

export const DELETE_PROMOTION_REQUESTED = "DELETE_PROMOTION_REQUESTED";
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_FAILED = "DELETE_PROMOTION_FAILED";

export const GET_DEAL_REQUESTED = "GET_DEAL_REQUESTED";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_FAILED = "GET_DEAL_FAILED";

export const ADD_DEAL_REQUESTED = "ADD_DEAL_REQUESTED";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_FAILED = "ADD_DEAL_FAILED";

export const DELETE_DEAL_REQUESTED = "DELETE_DEAL_REQUESTED";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_FAILED = "DELETE_DEAL_FAILED";

export const ADD_USER_REQUESTED = "ADD_USER_REQUESTED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export const GET_USER_REQUESTED = "GET_USER_REQUESTED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const VERIFIED_USER_REQUESTED = "VERIFIED_USER_REQUESTED";
export const VERIFIED_USER_SUCCESS = "VERIFIED_USER_SUCCESS";
export const VERIFIED_USER_FAILED = "VERIFIED_USER_FAILED";

export const BLOCK_USER_REQUESTED = "BLOCK_USER_REQUESTED";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAILED = "BLOCK_USER_FAILED";

export const SIGNUP_USER_REQUESTED = "SIGNUP_USER_REQUESTED";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILED = "SIGNUP_USER_FAILED";

export const EDIT_DEAL_REQUESTED = "EDIT_DEAL_REQUESTED";
export const EDIT_DEAL_SUCCESS = "EDIT_DEAL_SUCCESS";
export const EDIT_DEAL_FAILED = "EDIT_DEAL_FAILED";

export const EDIT_PROMOTION_REQUESTED = "EDIT_PROMOTION_REQUESTED";
export const EDIT_PROMOTION_SUCCESS = "EDIT_PROMOTION_SUCCESS";
export const EDIT_PROMOTION_FAILED = "EDIT_PROMOTION_FAILED";

export const EDIT_STORE_REQUESTED = "EDIT_STORE_REQUESTED";
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS";
export const EDIT_STORE_FAILED = "EDIT_STORE_FAILED";

export const SEARCH_REQUESTED = "SEARCH_REQUESTED";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILED = "SEARCH_FAILED";
