import axios from "axios";

export const refresh_token = async () => {
  const credentials = {
    refreshToken: `${localStorage.getItem("refresh_token")}`,
    email: `${localStorage.getItem("email")}`,
  };
  const { data } = await axios.post(
    "http://localhost:4000/api/v1/refresh-token",
    credentials
  );

 
  return data.users?.access_token;
};
