import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";

async function getApi(id) {
  const { data } = await axiosApiInstance.get(
    `${process.env.REACT_APP_API}/address/${id}`
  );
  return data?.store;
}

async function deleteApi(id) {
  const { data } = await toast.promise(
    axiosApiInstance.delete(`${process.env.REACT_APP_API}/address/${id}`),
    {
      loading: "Processing",
      error: "Error in deleting address",
      success: "Delete successfully...!",
    }
  );
  return data?.store;
}

async function editApi(id, edit) {
  const { data } = await toast.promise(
    axiosApiInstance.put(`${process.env.REACT_APP_API}/address/${id}`, edit),
    {
      loading: "Processing",
      error: "Error in edit address",
      success: "Edit successfully...!",
    }
  );
  return data?.store;
}

async function postApi(address) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/address`, address),
    {
      loading: "Proccessing...!",
      error: (error) => `Something error ${JSON.stringify(error)}`,
      success: "Address added successfully...!",
    }
  );
  return data;
}

function* getAddress(action) {
  try {
    const address = yield getApi(action.address);
    yield put({ type: "GET_ADDRESS_SUCCESS", address: address });
  } catch (error) {
    yield put({ type: "GET_ADDRESS_FAILED", message: error.message });
  }
}

function* addAddress(action) {
  try {
    const address = yield postApi(action.address);
    yield put({ type: "ADD_ADDRESS_SUCCESS", address: address });
  } catch (error) {
    yield put({ type: "ADD_ADDRESS_FAILED", message: error.message });
  }
}

function* deleteAddress(action) {
  try {
    const address = yield deleteApi(action.address?._id);
    yield put({ type: "DELETE_ADDRESS_SUCCESS", address: action.address });
  } catch (error) {
    yield put({ type: "DELETE_ADDRESS_FAILED", message: error.message });
  }
}
function* editAddress(action) {
  try {
    const address = yield editApi(action.address?.id, action.address.data);
    yield put({ type: "EDIT_ADDRESS_SUCCESS", address: action.address });
  } catch (error) {
    yield put({ type: "EDIT_ADDRESS_FAILED", message: error.message });
  }
}

function* addressSaga() {
  yield takeEvery("ADD_ADDRESS_REQUESTED", addAddress);
  yield takeEvery("GET_ADDRESS_REQUESTED", getAddress);
  yield takeEvery("DELETE_ADDRESS_REQUESTED", deleteAddress);
  yield takeEvery("EDIT_ADDRESS_REQUESTED", editAddress);
}

export default addressSaga;
