import axios from "axios";
import { access_token } from "../utils/getAccessToken";
import { refresh_token } from "../utils/refreshToken";

const axiosApiInstance = axios.create({
  headers: {
    origin: "*",
  },
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const value = await access_token();

    config.headers = {
      Authorization: `Bearer ${value}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log({ error });
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refresh_token();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { axiosApiInstance };
