import {
  GET_AUTH_FAILED,
  GET_AUTH_SUCCESS,
  GET_AUTH_REQUESTED,
  GET_REFRESH_TOKEN_FAILED,
  GET_REFRESH_TOKEN_REQUESTED,
  GET_REFRESH_TOKEN_SUCCESS,
  SIGNUP_USER_FAILED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_REQUESTED,
} from "../../type/index";

const initialState = {
  users: {},
  loading: false,
  error: null,
  signUpUser: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case GET_REFRESH_TOKEN_REQUESTED:
    case GET_AUTH_REQUESTED:
    case SIGNUP_USER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_AUTH_SUCCESS: {
      localStorage.setItem("access_token", action.users?.access_token);
      localStorage.setItem("refresh_token", action.users?.refresh_token);
      localStorage.setItem("email", action.users?.data?.email);
      localStorage.setItem("storeId", action.users?.data?.storeId?._id);
      localStorage.setItem("storeName", action.users?.data?.storeId?.name);
      localStorage.setItem("views", action.users?.data?.storeId?.views);
      localStorage.setItem(
        "description",
        action.users?.data?.storeId?.description
      );
      localStorage.setItem("userId", action.users?.data?._id);
      localStorage.setItem("image", action.users?.data?.storeId.storeImage);
      return {
        loading: false,
        users: action.users,
      };
    }
    case GET_AUTH_FAILED:
    case SIGNUP_USER_FAILED:
    case GET_REFRESH_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.users,
      };

    case GET_REFRESH_TOKEN_SUCCESS:
      return {
        loading: false,
        users: action.users,
      };
    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpUser: action.users,
      };

    default:
      return state;
  }
}
