import {
  DELETE_DEAL_FAILED,
  DELETE_DEAL_REQUESTED,
  DELETE_DEAL_SUCCESS,
  ADD_DEAL_FAILED,
  ADD_DEAL_REQUESTED,
  ADD_DEAL_SUCCESS,
  GET_DEAL_FAILED,
  GET_DEAL_REQUESTED,
  GET_DEAL_SUCCESS,
  EDIT_DEAL_SUCCESS,
  EDIT_DEAL_FAILED,
  EDIT_DEAL_REQUESTED,
} from "../../type/index";

const initialState = {
  deals: [],
  loading: false,
  error: null,
  lastDelete: {},
  lastAdded: {},
};

export default function deal(state = initialState, action) {
  switch (action.type) {
    case GET_DEAL_REQUESTED:
    case EDIT_DEAL_REQUESTED:
    case ADD_DEAL_REQUESTED:
    case DELETE_DEAL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_DEAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        deals: action.deal,
      };
    }

    case ADD_DEAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastAdded: action.deal,
      };
    }

    case DELETE_DEAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastDelete: action.deal,
      };
    }
    case EDIT_DEAL_SUCCESS: {
      const findIndex = state.deals.findIndex((p) => p?._id === action.deal.id);
      return {
        loading: false,
        deals: [...(state.deals[findIndex] = action.deal.data)],
      };
    }
    case GET_DEAL_FAILED:
    case ADD_DEAL_FAILED:
    case DELETE_DEAL_FAILED:
    case EDIT_DEAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.deal,
      };
    default:
      return state;
  }
}
