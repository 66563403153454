import {
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  ADD_USER_REQUESTED,
  GET_USER_REQUESTED,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  VERIFIED_USER_REQUESTED,
  VERIFIED_USER_FAILED,
  VERIFIED_USER_SUCCESS,
  BLOCK_USER_FAILED,
  BLOCK_USER_REQUESTED,
  BLOCK_USER_SUCCESS,
  EDIT_STORE_REQUESTED,
  EDIT_STORE_FAILED,
  EDIT_STORE_SUCCESS,
} from "../../type/index";

const initialState = {
  users: [],
  loading: false,
  error: null,
  lastAdded: null,
  lastDelete: null,
};

export default function endUser(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_REQUESTED:
    case GET_USER_REQUESTED:
    case VERIFIED_USER_REQUESTED:
    case BLOCK_USER_REQUESTED:
    case EDIT_STORE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.user,
      };
    }
    case EDIT_STORE_SUCCESS: {
      localStorage.setItem("storeName", action?.store?.name);
      localStorage.setItem("description", action?.store?.description);
      localStorage.setItem("image", action?.store?.storeImage);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_USER_FAILED:
    case GET_USER_FAILED:
    case VERIFIED_USER_FAILED:
    case BLOCK_USER_FAILED:
    case EDIT_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.user,
      };
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: [...state.users, action.user],
      };
    }
    case VERIFIED_USER_SUCCESS: {
      const index = state.users.findIndex((i) => i._id === action?.user?._id);

      return {
        ...state,
        loading: false,
        users: [...(state.users[index] = action.user)],
      };
    }
    case BLOCK_USER_SUCCESS: {
      const index = state.users.findIndex((i) => i._id === action?.user?._id);
      return {
        ...state,
        loading: false,
        users: [...(state.users[index] = action.user)],
      };
    }

    default:
      return state;
  }
}
