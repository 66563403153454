import { combineReducers } from "redux";
import address from "./address";
import users from "./authReducer";
import deals from "./deal";
import promotion from "./promotions";
import endUser from "./user";
import search from "./search";

const rootReducer = combineReducers({
  users: users,
  deals: deals,
  address: address,
  promotion: promotion,
  endUser: endUser,
  searchData: search,
});

export default rootReducer;
