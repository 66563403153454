import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import { fetchToken, onMessageListener } from "./service/firebase";

import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
const ForgotPasswordForm = lazy(() => import("./pages/forgetPasswordForm"));
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ProtectedRoutes = lazy(() => import("./components/ProtectedRoutes"));

function App() {
  const authState = useSelector((state) => state);
  // const [fcm, setFcm] = useState("");

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("PAYLOAD", payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // const onShowNotificationClicked = () => {
  //   setNotification({title: "Notification", body: "This is a test notification"})
  //   setShow(true);
  // }
  // useEffect(() => {
  //   fetchToken()
  //     .then((token) => {
  //       console.log("TOKEN", token);
  //       setFcm(token);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);
  // const onSendMessage = () => {
  //   console.log("CLICKED.....!");
  //   axiosApiInstance
  //     .post(`${process.env.REACT_APP_API}/store/notification`, { to: "cmyIu2fySROfLG4VuMSEwi:APA91bGJzGDNVGfLzrPf3Wbp5l_YF54nDBv-hvqtzeMKjHPTEN9edZ46BlluUPjizavJ8fTmtp412AMEcRS3rFF_mlv-3kkGyY5JfFBAkhNjDS15Qro498wOqc6XrkDT3GwUrehmXjF6" })
  //     .then((res) => console.log("response", res))
  //     .catch((er) => console.log({ er }));
  // };
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/new-password/:id" component={ForgotPasswordForm} />
          <ProtectedRoutes path="/app">
            <Layout />
          </ProtectedRoutes>

          <Route path="/login" component={Login} />
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
