import {
  GET_PROMOTION_FAILED,
  GET_PROMOTION_SUCCESS,
  GET_PROMOTION_REQUESTED,
  ADD_PROMOTION_FAILED,
  ADD_PROMOTION_REQUESTED,
  ADD_PROMOTION_SUCCESS,
  DELETE_PROMOTION_REQUESTED,
  DELETE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_FAILED,
  EDIT_PROMOTION_FAILED,
  EDIT_PROMOTION_REQUESTED,
  EDIT_PROMOTION_SUCCESS,
} from "../../type/index";

const initialState = {
  promotion: [],
  loading: false,
  error: null,
  lastDelete: {},
  lastAdded: {},
};

export default function promotion(state = initialState, action) {
  switch (action.type) {
    case GET_PROMOTION_REQUESTED:
    case ADD_PROMOTION_REQUESTED:
    case DELETE_PROMOTION_REQUESTED:
    case EDIT_PROMOTION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        promotion: action.promotion,
      };
    }
    case ADD_PROMOTION_FAILED:
    case GET_PROMOTION_FAILED:
    case DELETE_PROMOTION_FAILED:
    case EDIT_PROMOTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.promotion,
      };

    case ADD_PROMOTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastAdded: action.promotion,
      };
    }
    case EDIT_PROMOTION_SUCCESS: {
      const findIndex = state.promotion.findIndex(
        (p) => p?._id === action.promotion.id
      );
      return {
        ...state,
        loading: false,
        promotion: [...(state.promotion[findIndex] = action.promotion.data)],
      };
    }
    case DELETE_PROMOTION_SUCCESS: {
      const removeDeleted = state.promotion.filter(
        (p) => p._id !== action?.promotion?.id
      );
      return {
        ...state,
        loading: false,
        promotion: [...removeDeleted],
        lastDelete: action.promotion,
      };
    }
    default:
      return state;
  }
}
