import {
  SEARCH_FAILED,
  SEARCH_REQUESTED,
  SEARCH_SUCCESS,
} from "../../type/index";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_SUCCESS: {
      console.log("search",{action})
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.data?.data,
      };

    default:
      return state;
  }
}
