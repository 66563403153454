import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";

async function getApi(id) {
  const { data } = await axiosApiInstance.get(
    `${process.env.REACT_APP_API}/promotion/${id}`
  );
  return data.promotion;
}

async function deleteApi(deleteData) {
  const { data } = await toast.promise(
    axiosApiInstance.delete(
      `${process.env.REACT_APP_API}/promotion/${deleteData?.id}/${deleteData?.postBy}`
    ),
    {
      loading: "Processing...!",
      error: (state) => state.response.statusText,
      success: "Promotion delete successfully...!",
    }
  );
  return data.promotion;
}

async function postApi(promotion) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/promotion`, promotion),
    {
      loading: "Processing...!",
      error: (state) => state.response.statusText,
      success: "Promotion added successfully...!",
    }
  );
  return data.promotion;
}

async function editApi(promotion) {
  try {
    const { data } = await toast.promise(
      axiosApiInstance.put(
        `${process.env.REACT_APP_API}/promotion/` + promotion.id,
        promotion.data
      ),
      {
        loading: "Processing...!",
        error: (state) => JSON.stringify(state),
        success: (state) => {
          return state.data.message;
        },
      }
    );
    return data.promotion;
  } catch (error) {
    return error;
  }
}

function* getPromotionByStoreId(action) {
  try {
    const promotion = yield getApi(action.promotion);
    yield put({ type: "GET_PROMOTION_SUCCESS", promotion: promotion });
  } catch (error) {
    yield put({ type: "GET_PROMOTION_FAILED", message: error.message });
  }
}

function* addPromotion(action) {
  try {
    const promotion = yield postApi(action.promotion);
    yield put({
      type: "ADD_PROMOTION_SUCCESS",
      promotion: promotion,
    });
  } catch (error) {
    yield put({ type: "ADD_PROMOTION_FAILED", message: error.message });
  }
}

function* editPromotion(action) {
  try {
    const promotion = yield editApi(action.promotion);
    yield put({
      type: "EDIT_PROMOTION_SUCCESS",
      promotion: {
        data: action.promotion.data,
        id: action.promotion.id,
      },
    });
  } catch (error) {
    yield put({ type: "EDIT_PROMOTION_FAILED", message: error.message });
  }
}

function* deletePromotion(action) {
  try {
    yield deleteApi(action.promotion);
    yield put({
      type: "DELETE_PROMOTION_SUCCESS",
      promotion: action.promotion,
    });
  } catch (error) {
    yield put({ type: "DELETE_PROMOTION_FAILED", message: error.message });
  }
}

function* promotionSaga() {
  yield takeEvery("GET_PROMOTION_REQUESTED", getPromotionByStoreId);
  yield takeEvery("ADD_PROMOTION_REQUESTED", addPromotion);
  yield takeEvery("DELETE_PROMOTION_REQUESTED", deletePromotion);
  yield takeEvery("EDIT_PROMOTION_REQUESTED", editPromotion);
}

export default promotionSaga;
