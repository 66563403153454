// import "./wdyr";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import App from "./App";
import 'react-loading-skeleton/dist/skeleton.css'
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { AnimateSharedLayout } from "framer-motion";
import { Toaster } from "react-hot-toast";
// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }
import "./index.css";
ReactDOM.render(
  <AnimateSharedLayout type="crossfade">
    <Provider store={store}>
      <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill usePreferences>
            <App />
            <Toaster position="top-center" />
          </Windmill>
        </Suspense>
      </SidebarProvider>
    </Provider>
  </AnimateSharedLayout>,
  document.getElementById("root")
);

serviceWorker.register();
