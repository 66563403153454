import { all } from "redux-saga/effects";
import authSaga from "./AuthSaga";
import dealSaga from "./dealSaga";
import addressSaga from "./addressSaga";
import promotionSaga from "./promotionSaga";
import userSaga from "./userSaga";
import searchSaga from "./searchSaga";


//saga
export default function* rootSaga() {
  yield all([
    authSaga(),
    dealSaga(),
    addressSaga(),
    promotionSaga(),
    userSaga(),
    searchSaga(),
  ]);
}
