import { put, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";

async function loginApi(credentials) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/login`, credentials),
    {
      loading: "Processing",
      error: (err) => err.response?.statusText,
      success: (state) => {
        return state.data.message;
      },
    }
  );
  return data;
}

async function signUpApi(credentials) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/signup`, credentials),
    {
      loading: "Processing",
      error: (err) => err.response?.statusText,
      success: (data) => data.data.message,
    }
  );
  return data;
}

function* authUser(action) {
  try {
    const users = yield loginApi(action.users);
    yield put({ type: "GET_AUTH_SUCCESS", users: users });
  } catch (error) {
    yield put({ type: "GET_AUTH_FAILED", message: error.message });
  }
}

function* signUpUser(action) {
  try {
    const users = yield signUpApi(action.users);
    yield put({ type: "SIGNUP_USER_SUCCESS", users: users });
  } catch (error) {
    yield put({ type: "SIGNUP_USER_FAILED", message: error.message });
  }
}

function* authSaga() {
  yield takeLatest("GET_AUTH_REQUESTED", authUser);
  yield takeLatest("SIGNUP_USER_REQUESTED", signUpUser);
}

export default authSaga;
