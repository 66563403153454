import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";
async function getApi(id) {
  const { data } = await axiosApiInstance.get(
    `${process.env.REACT_APP_API}/deal/${id}`
  );
  return data.deal;
}

async function deleteApi(deleteData) {
  try {
    const deal = await toast.promise(
      axiosApiInstance.delete(
        `${process.env.REACT_APP_API}/deal/${deleteData?.id}/${deleteData.postBy}`
      ),
      {
        loading: "processing",
        error: (state) => state.response.statusText,
        success: (state) => state.data.message,
      }
    );
    return false;
  } catch (error) {
    return false;
  }
}

async function postApi(deal) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/deal`, deal),
    {
      loading: "Proccessing...!",
      error: (error) => `Something error ${JSON.stringify(error)}`,
      success: "Deal added successfully...!",
    }
  );
  return data?.deal;
}

async function editApi(deal) {
  try {
    const { data } = await toast.promise(
      axiosApiInstance.put(
        `${process.env.REACT_APP_API}/deal/` + deal.id,
        deal.data
      ),
      {
        loading: "Proccessing...!",
        error: (state) => state.response.statusText,
        success: "Deal update successfully...!",
      }
    );
    return data?.deal;
  } catch (error) {
    return error;
  }
}

function* getDealByStoreId(action) {
  try {
    const deal = yield getApi(action.deal);

    yield put({ type: "GET_DEAL_SUCCESS", deal: deal });
  } catch (error) {
    yield put({ type: "GET_DEAL_FAILED", message: error.message });
  }
}

function* addDeal(action) {
  try {
    const deal = yield postApi(action.deal);
    yield put({
      type: "ADD_DEAL_SUCCESS",
      deal: deal,
    });
  } catch (error) {
    yield put({ type: "ADD_DEAL_FAILED", message: error.message });
  }
}

function* editDeal(action) {
  try {
    const deal = yield editApi(action.deal);
    yield put({
      type: "EDIT_PROMOTION_SUCCESS",
      deal: deal,
    });
  } catch (error) {
    yield put({ type: "EDIT_DEAL_FAILED", message: error.message });
  }
}

function* deleteDeal(action) {
  try {
    const deal = yield deleteApi(action.deal);
    if (deal === false) {
      yield put({
        type: "DELETE_DEAL_FAILED",
        deal: "Delete failed",
      });
      return;
    } else {
      yield put({
        type: "DELETE_DEAL_SUCCESS",
        deal: deal,
      });
    }
  } catch (error) {
    yield put({ type: "DELETE_DEAL_FAILED", message: error.message });
  }
}

function* dealSaga() {
  yield takeEvery("GET_DEAL_REQUESTED", getDealByStoreId);
  yield takeEvery("ADD_DEAL_REQUESTED", addDeal);
  yield takeEvery("DELETE_DEAL_REQUESTED", deleteDeal);
  yield takeEvery("EDIT_DEAL_REQUESTED", editDeal);
}

export default dealSaga;
