import {
  GET_ADDRESS_FAILED,
  GET_ADDRESS_REQUESTED,
  GET_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_REQUESTED,
  DELETE_ADDRESS_SUCCESS,
  ADD_ADDRESS_REQUESTED,
  ADD_ADDRESS_FAILED,
  ADD_ADDRESS_SUCCESS,
  EDIT_ADDRESS_FAILED,
  EDIT_ADDRESS_REQUESTED,
  EDIT_ADDRESS_SUCCESS,
} from "../../type/index";

const initialState = {
  address: [],
  loading: false,
  error: null,
  lastAdded: null,
  lastDelete: null,
};

export default function address(state = initialState, action) {
  switch (action.type) {
    case EDIT_ADDRESS_REQUESTED:
    case DELETE_ADDRESS_REQUESTED:
    case ADD_ADDRESS_REQUESTED:
    case GET_ADDRESS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        address: action.address,
      };
    }
    case EDIT_ADDRESS_FAILED:
    case ADD_ADDRESS_FAILED:
    case DELETE_ADDRESS_FAILED:
    case GET_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.address,
      };
    case ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        address: [...state.address, action?.address],
      };
    }
    case DELETE_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        address: state?.address.filter((f) => f._id !== action?.address?._id),
      };
    }
    case EDIT_ADDRESS_SUCCESS: {
      const findAddressIndex = state.address.findIndex(
        (i) => i._id === action?.address?.id
      );
      const data = {
        _id: action?.address?.id,
        ...action.address?.data,
      };
      return {
        ...state,
        loading: false,
        address: [...state.address[findAddressIndex] = data],
      };
    }
    default:
      return state;
  }
}
