import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";

async function getApi(id) {
  const { data } = await axiosApiInstance.get(
    `${process.env.REACT_APP_API}/user/${id}`
  );
  return data?.data?.users;
}

async function editStoreApi(store, id) {
  const { data } = await toast.promise(
    axiosApiInstance.put(`${process.env.REACT_APP_API}/store/${id}`, store),
    {
      loading: "Processing",
      error: "Error in edit Store",
      success: (state) => {
        console.log("store update", { state });
        return "Store Update successfully...!";
      },
    }
  );
  return data;
}

async function blockApi(id) {
  const { data } = await toast.promise(
    axiosApiInstance.get(`${process.env.REACT_APP_API}/block-account/${id}`),
    {
      loading: "Processing",
      error: "Error in edit User",
      success: "Block Account successfully...!",
    }
  );
  return data?.data?.user;
}

async function verifyApi(id) {
  const { data } = await toast.promise(
    axiosApiInstance.get(`${process.env.REACT_APP_API}/verify-account/${id}`),
    {
      loading: "Processing",
      error: "Error in edit User",
      success: "Verify successfully...!",
    }
  );
  return data?.data?.user;
}

async function postApi(user) {
  const { data } = await toast.promise(
    axiosApiInstance.post(`${process.env.REACT_APP_API}/user`, user),
    {
      loading: "Processing...!",
      error: (error) => error.response.statusText,
      success: (message) => message.data.message,
    }
  );
  return data?.data?.users;
}

function* getUsers(action) {
  try {
    const user = yield getApi(action.user);
    yield put({ type: "GET_USER_SUCCESS", user: user });
  } catch (error) {
    yield put({ type: "GET_USER_FAILED", message: error.message });
  }
}
function* editStoreUser(action) {
  try {
    const user = yield editStoreApi(action.store.data, action.store.id);
    yield put({ type: "EDIT_STORE_SUCCESS", store: action.store.data });
  } catch (error) {
    yield put({ type: "EDIT_STORE_FAILED", message: error.message });
  }
}

function* addUser(action) {
  try {
    const user = yield postApi(action.user);
    yield put({ type: "ADD_USER_SUCCESS", user: user });
  } catch (error) {
    yield put({ type: "ADD_USER_FAILED", message: error.message });
  }
}

function* blockAccount(action) {
  try {
    const user = yield blockApi(action.user);
    yield put({ type: "BLOCK_USER_SUCCESS", user: user });
  } catch (error) {
    yield put({ type: "BLOCK_USER_FAILED", message: error.message });
  }
}
function* verifyAccount(action) {
  try {
    const user = yield verifyApi(action.user);
    yield put({ type: "VERIFIED_USER_SUCCESS", user: user });
  } catch (error) {
    yield put({ type: "VERIFIED_USER_FAILED", message: error.message });
  }
}

function* userSaga() {
  yield takeEvery("ADD_USER_REQUESTED", addUser);
  yield takeEvery("GET_USER_REQUESTED", getUsers);
  yield takeEvery("BLOCK_USER_REQUESTED", blockAccount);
  yield takeEvery("VERIFIED_USER_REQUESTED", verifyAccount);
  yield takeEvery("EDIT_STORE_REQUESTED", editStoreUser);
}

export default userSaga;
