import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { axiosApiInstance } from "../../../service/axios";
import toast from "react-hot-toast";

async function getApi(query) {
  const { data } = await toast.promise(
    axiosApiInstance.get(`${process.env.REACT_APP_API}/search?q=${query}`),
    {
      loading: "Proccessing...!",
      error: "Error in findng records",
      success: "Data Fetch Successfully...!",
    }
  );
  return data;
}

function* searches(action) {
  console.log("search data", { action });
  try {
    const data = yield getApi(action.query);
    console.log("search data", { data });
    yield put({ type: "SEARCH_SUCCESS", data: data });
  } catch (error) {
    yield put({ type: "SEARCH_FAILED", message: error.message });
  }
}

function* searchSaga() {
  yield takeEvery("SEARCH_REQUESTED", searches);
}

export default searchSaga;
